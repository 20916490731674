import { Search } from './model/search.model';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DashboardService {
  constructor() { }

  private searchType: Search[] = [
    { name: 'Exact Search Phrase', value: 'Exact Search Phrase' },
    { name: 'Text Search Phrase', value: 'Text Search Phrase' },
    { name: 'Partial Search Phrase', value: 'Partial Search Phrase' }];
  private documentRules: Search[] = [
    { name: 'datagridRule', value: 'Datagrid Rule' },
    { name: 'datagridRuleDescription', value: 'Datagrid Rule Description' },
    { name: 'datagridRuleName', value: 'Datagrid Rule Name' },
    { name: 'datagridRuleType', value: 'Datagrid Rule Type' },
    { name: 'signerDescription', value: 'Signer Description' }];
  private docProperties: Search[] = [
    { name: 'documentDisplayName', value: 'Document Display Name' },
    { name: 'documentID', value: 'Document ID' },
    { name: 'formName', value: 'Form Name' }];
  private docContents: Search[] = [
    { name: 'para', value: 'Para' }, { name: 'phrase', value: 'Phrase' },
    { name: 'subtitle', value: 'Subtitle' }, { name: 'title', value: 'Title' },
    { name: 'addressLine', value: 'Address Line' }];
  private documentPackages: Search[] = [
    { name: 'packageDescription', value: 'Package Description (Business Logic)' },
    { name: 'packageRule', value: 'Package Rule (PTR)' },
    { name: 'packages', value: 'Packages' }];
  private documentNotes: Search[] = [
    { name: 'note', value: 'Note' }];
  private staticContents: Search[] = [
    { name: 'staticContent', value: 'Static Content' }];
    private searchDocumentTests: Search[] = [
      { name: 'documentListName', value: 'Name' },
      { name: 'value', value: 'Value' }
    ];
  getSearchType() {
    return this.searchType.slice();
  }
  getDocumentRules() {
    return this.documentRules.slice();
  }
  getDocProperties() {
    return this.docProperties.slice();
  }
  getDocContents() {
    return this.docContents.slice();
  }
  getDocumentPackages() {
    return this.documentPackages.slice();
  }
  getDocumentNotes() {
    return this.documentNotes.slice();
  }
  getStaticContents() {
    return this.staticContents.slice();
  }

  getSearchDocumentTests(){
    return this.searchDocumentTests.slice();
  }
}
