import { NgModule } from '@angular/core';
import { MatToolbarModule } from'@angular/material/toolbar';
import { MatCardModule } from'@angular/material/card';
import { MatRadioModule } from'@angular/material/radio';
import {MatButtonModule} from '@angular/material/button';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatIconModule} from '@angular/material/icon';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
    imports:[
            MatToolbarModule,
            MatCardModule,
            MatRadioModule,
            MatButtonModule,
            MatGridListModule,
            MatSelectModule,
            MatInputModule,
            MatPaginatorModule,
            MatExpansionModule,
            MatSnackBarModule,
            MatIconModule,
            MatSlideToggleModule,
            MatCheckboxModule
                            ],

    exports:[MatToolbarModule,
            MatCardModule,
            MatRadioModule,
            MatButtonModule,
            MatGridListModule,
            MatSelectModule,
            MatInputModule,
            MatPaginatorModule,
            MatExpansionModule,
            MatSnackBarModule,
            MatIconModule,
            MatSlideToggleModule,
            MatCheckboxModule
                           ]

})
export class MaterialModule{}