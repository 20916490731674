import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { async } from 'rxjs/internal/scheduler/async';
import { AuthService } from '../auth/auth.service';

@Injectable({ providedIn: 'root' })
export class ResultService {
  private clientName: string;
  userName: string;
  private requestDataForDownload: {};
  private originalsearchFields = [];
  private originalSearchType: string;
  private originalSerchItem: string;
  private tempSearchFieldArray = [];
  private updatedFieldCountKey: string;
  private updatedpackageName: string;
  private serviceNameToCall: string;
  private currentFilterItem: string;
  private isSorting = false;
  private folderlocation: string;
  private searchAllFields = false;
  private updatedClientName = new Subject<string>();
  private updatedfolderLocation = new Subject<any>();
  private riaAPIURL = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router) { }
  getSearchType() {
    return this.originalSearchType;
  }
  getSearchField() {
    return this.originalsearchFields.slice();
  }
  getSearchItem() {
    return this.originalSerchItem;
  }
  fetchClientName(clientName) {
    if (clientName == 'Expere Base') {
      this.clientName = 'expere';
    } else if (clientName == 'Sun Trust') {
      this.clientName = 'suntrust';
    } else if (clientName == 'JPMC Private Banking') {
      this.clientName = 'jpmc';
    } else if (clientName == 'M&T') {
      this.clientName = 'mnt';
    } else if (clientName == 'BMO Harris') {
      this.clientName = 'bmoharris';
    } else if (clientName == 'Synovus Mortgage') {
      this.clientName = 'synovus';
    }
    else {
      return;
    }


    this.updatedClientName.next(this.clientName);
    this.getFolderLocation();
    this.router.navigate(['/dashboard']);
  }
  fetchUserName(name) {

    this.userName = name;
    this.userName = localStorage.getItem('uname');
  }
  getClientName() {
    return this.updatedClientName.asObservable();
  }
  getFolderpath() {
    return this.updatedfolderLocation.asObservable();
  }
  getFolderLocation() {
    const request_url = this.riaAPIURL + '/getClientInfo';
    const requestData = {
      clientName: this.clientName
    };
    var header = {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + "hhjhh")
    }
    this.http.post<{ path, date }>(request_url, requestData, header).subscribe(res => {
      this.folderlocation = res.path;
      this.updatedfolderLocation.next(res);
    });
  }
  onClickAnotherSearch() {
    this.getFolderLocation();
    this.router.navigate(['/dashboard']);
  }
  getSearchData(searchType: string, searchItem: string, searchField) {
    this.searchAllFields = false;
    this.originalSearchType = searchType;
    this.originalSerchItem = searchItem;
    const c = searchField.toString();
    const b = '\'' + c.split(',').join('\',\'') + '\'';
    const chk = b.split(',').map(st => st.replace(/['\'']+/g, ''));
    // console.log("befordata"+chk);
    const a = chk.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
    // console.log("afterData:--"+a);
    this.originalsearchFields = a;
    this.router.navigate(['/displayResult']);
  }
  getAllSecected(searchType: string, searchItem: string) {
    this.searchAllFields = true;
    this.originalSearchType = searchType;
    this.originalSerchItem = searchItem;
    this.originalsearchFields = ['datagridRule', 'datagridRuleDescription', 'datagridRuleName', 'datagridRuleType', 'signerDescription', 'documentDisplayName', 'documentID', 'formName', 'para', 'phrase', 'subtitle', 'title', 'addressLine', 'packageDescription', 'packageRule', 'packages', 'note', 'staticContent', 'documentListName', 'value'];
    this.router.navigate(['/displayResult']);
  }
  getDocumentSearchCount(SortByDocumentDisplayName, currentpage, contentPerPage) {
    this.serviceNameToCall = 'getDocumentSearchCount';
    const request_url = this.riaAPIURL + '/getDocumentSearchCount';
    const requestData = {
      clientName: this.clientName,
      searchFields: this.originalsearchFields,
      searchType: this.originalSearchType,
      searchItem: this.originalSerchItem,
      searchRange: [currentpage, contentPerPage],
      SortByDocumentDisplayName: SortByDocumentDisplayName,
      username: this.userName,
      searchAllFields: this.searchAllFields
    };

    return this.http.post<any>(request_url, requestData);
  }
  getDocumentResult(SortByDocumentDisplayName, currentpage, contentPerPage) {
    this.serviceNameToCall = 'getDocumentResult';
    const request_url = this.riaAPIURL + '/getDocumentResult';
    const requestData = {
      clientName: this.clientName,
      searchFields: this.originalsearchFields,
      searchType: this.originalSearchType,
      searchItem: this.originalSerchItem,
      searchRange: [currentpage, contentPerPage],
      SortByDocumentDisplayName: SortByDocumentDisplayName,
      username: this.userName,
      searchAllFields: this.searchAllFields
    };

    return this.http.post<any>(request_url, requestData);
  }
  getDocuementPackageCount(SortByDocumentDisplayName, currentpage, contentPerPage) {
    this.serviceNameToCall = 'getDocuementPackageCount';
    const request_url = this.riaAPIURL + '/getDocuementPackageCount';
    const requestData = {
      clientName: this.clientName,
      searchFields: this.originalsearchFields,
      searchType: this.originalSearchType,
      searchItem: this.originalSerchItem,
      searchRange: [currentpage, contentPerPage],
      SortByDocumentDisplayName: SortByDocumentDisplayName,
      username: this.userName,
      searchAllFields: this.searchAllFields
    };

    return this.http.post<any>(request_url, requestData);
  }

  getPackageResult(packageName, SortByDocumentDisplayName, currentpage, contentPerPage) {
    this.serviceNameToCall = 'getPackageResult';
    this.updatedpackageName = packageName;
    const request_url = this.riaAPIURL + '/getDocuementPackageResult';
    const requestData = {
      clientName: this.clientName,
      searchFields: this.originalsearchFields,
      packageValue: this.updatedpackageName,
      searchType: this.originalSearchType,
      searchItem: this.originalSerchItem,
      searchRange: [currentpage, contentPerPage],
      SortByDocumentDisplayName: SortByDocumentDisplayName,
      username: this.userName,
      searchAllFields: this.searchAllFields
    };
    //  console.log(requestData);
    return this.http.post<{ fieldCount, commonCount, docResult }>(request_url, requestData);
  }
  getFieldResult(fieldCountKey, SortByDocumentDisplayName, currentpage, contentPerPage) {
    this.updatedFieldCountKey = fieldCountKey;
    this.serviceNameToCall = 'getFieldResult';
    const request_url = this.riaAPIURL + '/getDocumentResult';
    const requestData = {
      clientName: this.clientName,
      searchFields: [this.updatedFieldCountKey],
      searchType: this.originalSearchType,
      searchItem: this.originalSerchItem,
      searchRange: [currentpage, contentPerPage],
      SortByDocumentDisplayName: SortByDocumentDisplayName,
      username: this.userName,
      searchAllFields: this.searchAllFields,
      filter: true
    };
    return this.http.post<any>(request_url, requestData);
  }
  getFieldResultCount(fieldCountKey, SortByDocumentDisplayName, currentpage, contentPerPage) {
    this.updatedFieldCountKey = fieldCountKey;
    this.serviceNameToCall = 'getFieldResult';
    const request_url = this.riaAPIURL + '/getDocumentSearchCount';
    const requestData = {
      clientName: this.clientName,
      searchFields: [this.updatedFieldCountKey],
      searchType: this.originalSearchType,
      searchItem: this.originalSerchItem,
      searchRange: [currentpage, contentPerPage],
      SortByDocumentDisplayName: SortByDocumentDisplayName,
      username: this.userName,
      searchAllFields: this.searchAllFields
    };
    return this.http.post<any>(request_url, requestData);
  }
  download(sorted, filter) {
    this.isSorting = sorted;
    this.currentFilterItem = filter;
    this.tempSearchFieldArray = [filter];
    const request_url = this.riaAPIURL + '/exportToExcel';
    if (this.serviceNameToCall == 'getPackageResult') {
      // console.log("getPackageResult");
      this.requestDataForDownload = {
        clientName: this.clientName,
        searchFields: this.originalsearchFields,
        packageValue: this.updatedpackageName,
        searchType: this.originalSearchType,
        searchItem: this.originalSerchItem,
        SortByDocumentDisplayName: this.isSorting,
        Filters: this.currentFilterItem,
        Fields: this.originalsearchFields,
      };
    } else if (this.serviceNameToCall == 'getFieldResult') {
      //  console.log("getFieldResult");
      this.requestDataForDownload = {
        clientName: this.clientName,
        searchFields: this.tempSearchFieldArray,
        packageValue: '',
        searchType: this.originalSearchType,
        searchItem: this.originalSerchItem,
        SortByDocumentDisplayName: this.isSorting,
        Filters: this.currentFilterItem,
        Fields: this.originalsearchFields,
      };
    } else {
      this.requestDataForDownload = {
        clientName: this.clientName,
        searchFields: this.originalsearchFields,
        packageValue: '',
        searchType: this.originalSearchType,
        searchItem: this.originalSerchItem,
        SortByDocumentDisplayName: this.isSorting,
        Filters: 'None',
        Fields: this.originalsearchFields,

      };
    }
    // console.log("doenloadexel request"+JSON.stringify(this.requestDataForDownload));
    return this.http.post(request_url, this.requestDataForDownload, { responseType: 'blob', });
  }

  upload(params: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('clientName', params.clientName);
    formData.append('S3FolderPath', params.release)
    formData.append('file', params.file, params.file.name);

    return this.http.post<any>(this.riaAPIURL + "/uploadFile", formData);
  }

  index(param) {
    return this.http.post<any>(this.riaAPIURL + "/solrIndexing", param);
  }

  getIndexPendingList() {
    return this.http.get<any>(this.riaAPIURL + "/getUploadFileStatus");
  }

}
