import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { NgForm, FormBuilder } from '@angular/forms';
import { Validators} from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.css']
})
export class CreateUserComponent implements OnInit, OnDestroy{
  createUserForm: FormGroup;
  roles= [];
  accesses=[];
  userdata:{};
  adminRole:boolean=true;
  isLoading:boolean;
  private authListenerSubs: Subscription;
constructor(private authService:AuthService,private router:Router,private formBuilder: FormBuilder,private snackBar:MatSnackBar) {
  this.roles = [
    {name: 'User', value: 'user'},
    {name: 'Admin', value: 'Admin'},

  ];
  this.accesses = [ {name: 'Expere Base', value: 'Expere Base'},
               {name: 'Sun Trust', value: 'Sun Trust'},
               {name: 'JPMC Private Banking', value: 'JPMC Private Banking'},
               {name: 'M&T', value: 'M&T'},
               {name: 'BMO Harris', value: 'BMO Harris'},
               {name: 'Synovus Mortgage', value:'Synovus Mortgage'},
              ];
            }
        
  ngOnInit() {
    this.initCreateUserForm();
     this.onChanges();
     this.authListenerSubs=this.authService.getAuthStatusListener().subscribe(authstatus=>{
       this.isLoading=false;
     })
  }


  initCreateUserForm() {
    this.createUserForm = new FormGroup({
      selectRoleFormControl: new FormControl('', [
        Validators.required,
      ]),
      accessFormControl : new FormControl('', [
        Validators.required,
      ]),
      emailFormControl :new FormControl('', [
        Validators.required, 
      ]),
      passwordFormControl : new FormControl('', [
        Validators.required,
      ])
     });
  }
  onChanges() {
    this.createUserForm.get('selectRoleFormControl').valueChanges
    .subscribe(selectedrole => {
        if (selectedrole!= 'user') {
          this.adminRole=false;
             this.createUserForm.get('passwordFormControl').reset();
            this.createUserForm.get('passwordFormControl').disable();
            this.createUserForm.get('accessFormControl').reset();
            this.createUserForm.get('accessFormControl').disable(); 

        }
        else {
          this.adminRole=true;
             this.createUserForm.get('passwordFormControl').enable();
            this.createUserForm.get('accessFormControl').enable(); 
        }
    });
}
 
onCreateUser(form: FormGroup) {
  if(form.invalid){
    this.snackBar.open("Please Fill Out All Fields", null, {duration:3000,verticalPosition: 'top'});
    return;
  } 
     this.isLoading=true;
    if(this.createUserForm.get('selectRoleFormControl').value=='Admin'){
      this.userdata={username: form.value.emailFormControl, role: "Admin"}
  
    }
    else{
      this.userdata={username: form.value.emailFormControl, role: "user",password:form.value.passwordFormControl,access:form.value.accessFormControl} 
    }
    this.authService.onCreateUser(this.userdata); 
  }
  ngOnDestroy(){
    this.authListenerSubs.unsubscribe();
  }

}
