import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { ResultService } from '../result.service';
import { DomSanitizer } from '@angular/platform-browser';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-display.result',
  templateUrl: './display.result.component.html',
  styleUrls: ['./display.result.component.css']
})
export class DisplayResultComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  //...................Resut Display amd update DOM.........
  searchFields = [];
  searchType: string;
  searchItem: string;
  filterAppliesText: string = "No filters applied";
  packages = [];
  fieldcounts: {};
  resultCount: number;
  fetchResult = [];
  serviceNameToCall: string;
  selectedField: string;
  selectedPackageName: string;
  fieldValueCSS;
  //...................Pagination Part .........
  totalContent = 50;
  contentPerPage = 10;
  currentPage = 0;
  contentSizeOption = [10, 15, 20, 25];
  //...............loading part ..............
  isLoading: boolean = true;
  isProgress: boolean = false;
  //.....For Download Excel.........
  sorted: boolean = false;
  filteredPackageorField: string;
  //------new code-----
  totalResult = [];
  totalFieldsCount = [];
  totalPackageResult = [];

  constructor(private router: Router, private snackBar: MatSnackBar, private resultService: ResultService, private sanitizer: DomSanitizer) { }
  ngOnInit() {
    this.fieldValueCSS = Math.floor((Math.random() * 6) + 1);
    this.searchType = this.resultService.getSearchType();
    this.searchItem = this.resultService.getSearchItem();
    this.searchFields = this.resultService.getSearchField();
    console.log(this.searchFields.length)
    if (this.searchFields.length === 17) {
      this.searchFields = ['All fields']
    }
    this.resultService.getDocumentSearchCount(false, this.currentPage, this.contentPerPage).subscribe(res => {
      console.log("resultofCount---", JSON.stringify(res))
      this.resultCount = res.commonCount;
      this.totalContent = res.commonCount;
      delete res.commonCount;
      this.fieldcounts = res;
      console.log("result---", JSON.stringify(this.fieldcounts))
      this.resultService.getDocumentResult(false, this.currentPage, this.contentPerPage).subscribe(res => {
        this.fetchResult = res.docResult;
        console.log("result---", JSON.stringify(res))
        this.isLoading = false;
        this.resultService.getDocuementPackageCount(false, this.currentPage, this.contentPerPage).subscribe(res => {
          this.packages = res;
          console.log("result---", JSON.stringify(res))
        }, error => {
          this.isLoading = false;
          this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
        })
      }, error => {
        this.isLoading = false;
        this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
      })
    }, error => {
      this.isLoading = false;
      this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
    })

  }

  setClickedRow(event) {
    console.log("event ..." + event)
  }
  sortNull() {
    return 0;
  }
  onGetFieldResult(fieldCountKey) {
    //console.log(fieldCountKey);
    this.fieldValueCSS = fieldCountKey;
    this.serviceNameToCall = "getFieldResult";
    this.selectedField = fieldCountKey;
    this.filteredPackageorField = fieldCountKey;
    this.sorted = false;
    this.isLoading = true;
    this.filterAppliesText = fieldCountKey;
    this.resultService.getFieldResult(fieldCountKey, false, 0, 10).subscribe(res => {
      this.fetchResult = res.docResult;
      this.contentPerPage = 10;
      this.paginator.pageIndex = 0;
      this.resultService.getFieldResultCount(fieldCountKey, false, 0, 10).subscribe(res => {
        this.resultCount = res.commonCount;
        this.totalContent = res.commonCount;
      }, error => {
        this.isLoading = false;
        this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
      })
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
    }
    );
  }
  onGetPackageData(packageName) {
    this.fieldValueCSS = Math.floor((Math.random() * 6) + 1);
    this.serviceNameToCall = "getPackageResult";
    this.selectedPackageName = packageName;
    this.filteredPackageorField = packageName;
    this.sorted = false;
    this.isLoading = true;
    this.filterAppliesText = packageName;
    this.resultService.getPackageResult(packageName, false, 0, 10).subscribe(res => {
      this.fetchResult = res.docResult;
      const tempPackageCount = this.packages.filter(pack => pack.packageName === this.selectedPackageName);
      console.log(" tempPackageCount--", JSON.stringify(tempPackageCount));
      this.resultCount = tempPackageCount[0].packageCount;
      this.totalContent = tempPackageCount[0].packageCount;
      this.contentPerPage = 10;
      this.paginator.pageIndex = 0;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
    }
    );
  }
  onBackToOriginalResult() {
    this.fieldValueCSS = Math.floor((Math.random() * 6) + 1);
    this.serviceNameToCall = "getBacktoOriginalResult";
    this.sorted = false;
    this.isLoading = true;
    this.filterAppliesText = "No filters applied";
    this.resultService.getDocumentSearchCount(false, this.currentPage, this.contentPerPage).subscribe(res => {
      console.log("resultofCount---", JSON.stringify(res))
      this.resultCount = res.commonCount;
      this.totalContent = res.commonCount;
      this.resultService.getDocumentResult(false, this.currentPage, this.contentPerPage).subscribe(res => {
        this.fetchResult = res.docResult;
        this.contentPerPage = 10;
        this.paginator.pageIndex = 0;
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
      })
    }, error => {
      this.isLoading = false;
      this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
    })

  }
  onSortAlphabatically() {
    this.sorted = true;
    this.isLoading = true;
    if (this.serviceNameToCall == "getFieldResult") {
      this.resultService.getFieldResult(this.selectedField, true, 0, 10).subscribe(res => {
        this.fetchResult = res.docResult;
        this.contentPerPage = 10;
        this.paginator.pageIndex = 0;
        this.resultService.getFieldResultCount(this.selectedField, true, 0, 10).subscribe(res => {
          this.resultCount = res.commonCount;
          this.totalContent = res.commonCount;
        }, error => {
          this.isLoading = false;
          this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
        })
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
      }
      );
    }
    else if (this.serviceNameToCall == "getPackageResult") {
      this.resultService.getPackageResult(this.selectedPackageName, true, 0, 10).subscribe(res => {
        this.fetchResult = res.docResult;
        this.contentPerPage = 10;
        this.paginator.pageIndex = 0;
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
      }
      );
    }
    else {
      this.resultService.getDocumentResult(true, 0, 10).subscribe(res => {
        this.fetchResult = res.docResult;
        this.contentPerPage = 10;
        this.paginator.pageIndex = 0;
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
      })

    }
  }
  onChangePageEvent(pageData: PageEvent) {
    //console.log(pageData);
    this.isLoading = true;
    this.currentPage = pageData.pageIndex;
    let startingIndex = this.currentPage * this.contentPerPage;
    if (((this.currentPage + 1) * this.contentPerPage) > this.resultCount) {
      this.contentPerPage = this.resultCount - (this.currentPage * this.contentPerPage);
    } else { this.contentPerPage = pageData.pageSize; }
    if (this.serviceNameToCall == "getFieldResult") {
      this.resultService.getFieldResult(this.selectedField, this.sorted, startingIndex, this.contentPerPage).subscribe(res => {
        this.fetchResult = res.docResult;
        this.resultService.getFieldResultCount(this.selectedField, this.sorted, startingIndex, this.contentPerPage).subscribe(res => {
          this.resultCount = res.commonCount;
          this.totalContent = res.commonCount;
        }, error => {
          this.isLoading = false;
          this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
        })
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
      }
      );

    }
    else if (this.serviceNameToCall == "getPackageResult") {

      this.resultService.getPackageResult(this.selectedPackageName, this.sorted, startingIndex, this.contentPerPage).subscribe(res => {
        this.fetchResult = res.docResult;
        const tempPackageCount = this.packages.filter(pack => pack.packageName === this.selectedPackageName);
        console.log(" tempPackageCount--", JSON.stringify(tempPackageCount));
        this.resultCount = tempPackageCount[0].packageCount;
        this.totalContent = tempPackageCount[0].packageCount;
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
        this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
      });
    }
    else {
      this.resultService.getDocumentSearchCount(this.sorted, startingIndex, this.contentPerPage).subscribe(res => {
        console.log("resultofCount---", JSON.stringify(res))
        this.resultCount = res.commonCount;
        this.totalContent = res.commonCount;
        this.resultService.getDocumentResult(this.sorted, startingIndex, this.contentPerPage).subscribe(res => {
          this.fetchResult = res.docResult;
          this.isLoading = false;
        }, error => {
          this.isLoading = false;
          this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
        })
      }, error => {
        this.isLoading = false;
        this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
      })

    }
    this.contentPerPage = pageData.pageSize;
  }
  downloadExcel() {
    this.isLoading = true;
    this.isProgress = true;

    this.resultService.download(this.sorted, this.filteredPackageorField).subscribe(res => {
      this.downloadFile(res, 'application/xlsx', 'result.xlsx')
      this.isLoading = false;
      this.isProgress = false;
    }, error => {
      this.isLoading = false;
      this.isProgress = false;
      this.snackBar.open("Error : Service Not Avaiable, Please Try After Some Time", null, { duration: 3000, verticalPosition: 'top' });
    }
    );
  }
  anotherSearch() {
    this.resultService.onClickAnotherSearch();
  }
  getAllKeys(Result) {
    var keys = [];
    for (var k in Result) {
      if (k == "formNameHeader" || k == "documentIDHeader" || k == "packagesHeader" || k == "$$hashKey") {
        continue;
      }
      keys.push(k);
    }
    //console.log(keys)
    return keys;
  }
  printHTML(htmlStr) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlStr);
  }
  downloadFile(blob: any, type: string, filename: string): string {
    const url = window.URL.createObjectURL(blob); // work with blob directly
    // create hidden dom element (so it works in all browsers)
    const a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    // create file, attach to hidden element and open hidden element
    a.href = url;
    a.download = filename;
    a.click();
    return url;
  }


}
