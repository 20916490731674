import { Component, OnInit, OnDestroy} from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResultService } from '../../result/result.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
   isLoading: boolean;
  private authListenerSubs: Subscription;
  constructor(private loginService: AuthService, private snackBar: MatSnackBar, private resultService: ResultService) {}
ngOnInit() {
this.authListenerSubs = this.loginService.getAuthStatusListener().subscribe(authstatus => {
  this.isLoading = false;
});
this.loginService.logout();
}
  OnLogin(form: NgForm) {
    if (form.invalid) {
      this.snackBar.open('Please Fill Out All Fields', null, {duration: 3000, verticalPosition: 'top'});
      return;
    }
    this.isLoading = true;
    this.resultService.fetchUserName(form.value.username);
    this.loginService.Login({ username: form.value.username, password: form.value.password });

  }
  ngOnDestroy() {
  this.authListenerSubs.unsubscribe();
  }
}
