
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthData } from './auth-data.model';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../environments/environment';
import { ResultService } from '../result/result.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private description: string;
  private status: string;
  private username: string;
  private tokenTimer: any;
  private token: string;
  private isAdmin: boolean;
  private accessGranted: any = [];
  private isAuthenticated: boolean;
  private authStatusListener = new Subject<boolean>();
  private singleClientCase = new Subject<boolean>();
  private updatedUserName = new Subject<string>();
  private riaAPIURL = environment.apiUrl;
  constructor(private http: HttpClient, private router: Router, private snackbar: MatSnackBar, private resultservice: ResultService) { }

  Login({ username, password }: { username: string; password: string; }) {
    const authdata: AuthData = { username, password };
    this.username = username;
    this.updatedUserName.next(username);
    // tslint:disable-next-line: max-line-length
    this.http.post<{ status: string, access: any, role: string, JWToken: string, validity: string, Descriptioon: string }>(this.riaAPIURL + '/login', authdata)
      .subscribe(response => {
        // console.log('-----------', JSON.stringify(response));
        const status = response.status;
        this.status = status;
        const fectchAccess = response.access;
        const role = response.role;
        if (status === 'Success') {
          console.log("response", response.validity);
          localStorage.setItem('uname', this.username);
          //this.token="fdfdfaefw4rqdar425242rwerwerfdfd"
          this.token = response.JWToken;
          // const expiresInDuration=3600
          const expiresInDuration = parseInt(response.validity) / 1000;
          console.log("expiresInDuration", expiresInDuration);
          this.setAuthTimer(expiresInDuration);
          this.setAuthAlert(expiresInDuration);
          this.isAuthenticated = true;
          this.authStatusListener.next(true);
          const now = new Date();
          const expirationDate = new Date(now.getTime() + expiresInDuration * 1000);
          console.log(expirationDate);
          this.saveAuthData(this.token, expirationDate);
          if (role === 'Admin') {
            this.isAdmin = true;
            localStorage.setItem('role', role);
            this.accessGranted = ['Expere Base', 'Sun Trust', 'JPMC Private Banking', 'M&T', 'BMO Harris', 'Synovus Mortgage'];
            localStorage.setItem('clientList', this.accessGranted);
            this.singleClientCase.next(false);
            this.router.navigate(['/selectClient']); this.router.navigate(['/selectClient']);
          } else {
            if (fectchAccess === 'All') {
              console.log('in all user')
              this.isAdmin = false;
              this.accessGranted = ['Expere Base', 'Sun Trust', 'JPMC Private Banking', 'M&T', 'BMO Harris', 'Synovus Mortgage'];
              localStorage.setItem('clientList', this.accessGranted);
              this.singleClientCase.next(false);
              this.router.navigate(['/selectClient']);
            } else {
              this.isAdmin = false;
              this.accessGranted = JSON.parse(fectchAccess);
              localStorage.setItem('clientList', this.accessGranted);
              console.log('lenth' + this.accessGranted.length);
              if (this.accessGranted.length === 1) {
                this.resultservice.fetchClientName(this.accessGranted[0]);
                this.singleClientCase.next(true);
                this.resultservice.fetchUserName(this.username);
                this.router.navigate(['/dashboard']);
              } else {
                this.singleClientCase.next(false);
                this.router.navigate(['/selectClient']);
              }
            }
          }
        } else {
          this.description = response.Descriptioon;
          this.authStatusListener.next(false);
          this.snackbar.open(this.description, null, { duration: 3000, verticalPosition: 'top' });
        }
      }, error => {
        this.authStatusListener.next(false);
        this.snackbar.open('Error : Service Not Avaiable, Please Try After Some Time', null, { duration: 3000, verticalPosition: 'top' });
      });

  }

  onCreateUser(userdata) {
    const createUserdata = userdata;
    this.http.post<{ description: string }>(this.riaAPIURL + '/createUser', createUserdata)
      .subscribe(response => {
        // console.log('hi this is response' + JSON.stringify(response));
        this.description = response.description;
        this.snackbar.open(this.description, null, { duration: 3000, });
        if (this.description === 'User has been created') {
          this.router.navigate(['/selectClient']);
        } else {
          this.snackbar.open(this.description, null, { duration: 3000, verticalPosition: 'top' });
          this.authStatusListener.next(false);
        }
      },
        error => {
          this.authStatusListener.next(false);
          this.snackbar.open('Error : Service Not Avaiable, Please Try After Some Time', null, { duration: 3000, verticalPosition: 'top' });
        });

  }
  getUserName() {
    return this.updatedUserName.asObservable();
  }
  getSingleClientCase() {
    return this.singleClientCase.asObservable();
  }
  getToken() {
    return this.token;
  }
  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }
  getIsAuthenticated() {
    return this.isAuthenticated;
  }
  getAccessedClient() {
    return this.accessGranted.slice();
  }
  logout() {
    this.token = null;
    this.status = 'Fail';
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(['/']);
  }
  getAdminStatus() {
    return this.isAdmin;
  }




  // ------------TokenAuthenticATION
  autoAuthUser() {
    this.router.navigate(['/selectClient']);
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.setAuthTimer(expiresIn / 1000);
      console.log('TimerData', this.tokenTimer);
      this.authStatusListener.next(true);
    }
  }

  private setAuthTimer(duration: number) {
    console.log('Setting timer: ' + duration);
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }
  private setAuthAlert(duration: number) {
    setTimeout(() => {
      this.snackbar.open('Your Session will expire after 60 seconds', null, { duration: 5000, verticalPosition: 'top' });
    }, (duration - 60) * 1000);
  }

  private saveAuthData(token: string, expirationDate: Date) {
    localStorage.setItem('token', token);
    localStorage.setItem('expiration', expirationDate.toISOString());
  }

  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('expiration');
    localStorage.removeItem('clientList');
    localStorage.removeItem('uname');
    localStorage.removeItem('role');
  }

  private getAuthData() {
    const token = localStorage.getItem('token');
    const expirationDate = localStorage.getItem('expiration');
    if (!token || !expirationDate) {
      return;
    }
    return {
      token,
      expirationDate: new Date(expirationDate)
    };
  }



}
