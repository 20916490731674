import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../pages/auth/auth.service';
import { Subscription } from 'rxjs';
import { DashboardService } from '../../pages/dashboard/dashboard.service';
import { Router } from '@angular/router';
import { ResultService } from '../../pages/result/result.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
  userName:string;
  clientName:string;
  private authListenerSubs: Subscription;
  private userNameSubs: Subscription;
  private clientNameSubs: Subscription;
  private singleClientAccessSub: Subscription;
  userIsAuthenticated=false;
  singleClientAccess=false;
  accessedClient:any=[];
  constructor(private authService:AuthService, private resultService:ResultService,public router:Router) { }

  ngOnInit() {
    this.userName=localStorage.getItem('uname');
    this.userNameSubs=this.authService.getUserName().subscribe(username=>this.userName=username);
    this.userIsAuthenticated=this.authService.getIsAuthenticated();
    this.authListenerSubs=this.authService.getAuthStatusListener().subscribe(isAuthenticated=>{
    this.userIsAuthenticated=isAuthenticated;
    }); 
     this.clientNameSubs=this.resultService.getClientName().subscribe(clientName=>{
      this.clientName=clientName;
     });  
    this.singleClientAccessSub=this.authService.getSingleClientCase().subscribe(res=>{
     // console.log("singleClientCase   "+res)
      this.singleClientAccess=res;
    })
  }
  dropdown(){
    var array = localStorage.getItem('clientList').split(',');
    this.accessedClient=this.authService.getAccessedClient(); 
    // console.log(array)
    this.accessedClient=array
    
  
  }
  clientSelection(clientName){
  this.resultService.fetchClientName(clientName);
 }
  logout(){
  this.clientName=null;
  this.authService.logout();
}
ngOnDestroy(){
this.authListenerSubs.unsubscribe();
this.userNameSubs.unsubscribe();
this.clientNameSubs.unsubscribe();
this.singleClientAccessSub.unsubscribe();
}
}
