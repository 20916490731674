import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { MaterialModule } from './material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { AppComponent } from './app.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { NavbarComponent } from './common/navbar/navbar.component';

import { AuthInterceptor } from './pages/auth/auth.intercepter';
import { ClientSelectionComponent } from './pages/client-selection/client-selection.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DisplayResultComponent } from './pages/result/display.result/display.result.component';
import { CreateUserComponent } from './pages/auth/create-user/create-user.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavbarComponent,
    ClientSelectionComponent,
    DashboardComponent,
    DisplayResultComponent,
    CreateUserComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [{
    provide:HTTP_INTERCEPTORS, useClass: AuthInterceptor,multi:true
  },{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
