import { NgModule } from '@angular/core';
import { LoginComponent } from './pages/auth/login/login.component';
import { Routes, RouterModule } from '@angular/router';
import { ClientSelectionComponent } from './pages/client-selection/client-selection.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DisplayResultComponent } from './pages/result/display.result/display.result.component';
import { AuthGuard } from './pages/auth/auth.guard';
import { CreateUserComponent } from './pages/auth/create-user/create-user.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'selectClient', component: ClientSelectionComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'displayResult', component: DisplayResultComponent, canActivate: [AuthGuard] },
  { path: 'createUser', component: CreateUserComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
