import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ResultService } from '../result/result.service';
import { AuthService } from '../auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-client-selection',
  templateUrl: './client-selection.component.html',
  styleUrls: ['./client-selection.component.css']
})
export class ClientSelectionComponent implements OnInit {
  accessedClient: any = [];
  indexPendingClient = [];
  isAdmin = false;
  file;
  indexParam = {
    "clientName": "",
    "S3FolderPath": "",
    "folderLocation": "",
    "SolrUrl": ""
  }
  uploadParam = {
    "clientName": "",
    "release": "",
    "file": null
  }
  isUploading = false;
  isUploaded = false;
  isIndexing = false;
  errorMessage = '';
  constructor(private router: Router, private resultService: ResultService, private authService: AuthService, private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.resultService.fetchUserName(localStorage.getItem('uname'));
    // console.log(localStorage.getItem('uname'))
    let array = localStorage.getItem('clientList').split(',');
    this.accessedClient = this.authService.getAccessedClient();
    this.accessedClient = array;
    this.getPendingIndexingClients();

    // this.accessedClient.push(localStorage.getItem('clientList'));
    if (localStorage.getItem('role')) {
      this.isAdmin = true;
    }

  }

  getPendingIndexingClients() {
    this.indexPendingClient = [];
    this.resultService.getIndexPendingList().subscribe((response) => {
      console.log('res: ', response);
      if (response.length !== 0) {
        response.forEach(client => {
          switch (client) {
            case "expere":
              this.indexPendingClient.push('Expere Base');
              break;
            case "suntrust":
              this.indexPendingClient.push('Sun Trust');
              break;
            case "jpmc":
              this.indexPendingClient.push('JPMC Private Banking');
              break;
            case "mnt":
              this.indexPendingClient.push('M&T');
              break;
            case "bmoharris":
              this.indexPendingClient.push('BMO Harris');
              break;
            case "synovus":
              this.indexPendingClient.push('Synovus Mortgage');
              break;
            default:
              this.indexPendingClient.push(client);
              break;
          }
        });
      }
    });
  }

  clientSelection(clientName) {
    this.resultService.fetchClientName(clientName);
  }

  onCreateUser() {
    this.router.navigate(['/createUser']);
  }

  onfileSelect(event) {
    this.uploadParam.file = event.target.files[0];
  }

  uploadFile() {
    this.isUploading = true;
    switch (this.uploadParam.clientName) {
      case "Expere Base":
        this.uploadParam.clientName = "expere";
        break;
      case "Sun Trust":
        this.uploadParam.clientName = "suntrust";
        break;
      case "JPMC Private Banking":
        this.uploadParam.clientName = "jpmc";
        break;
      case "M&T":
        this.uploadParam.clientName = "mnt";
        break;
      case "BMO Harris":
        this.uploadParam.clientName = "bmoharris";
        break;
      case "Synovus Mortgage":
        this.uploadParam.clientName = "synovus";
        break;
      default:
        break;
    }
    this.resultService.upload(this.uploadParam).subscribe(response => {
      this.isUploading = false;
      if (response.status == 'Error') {
        this.openSnackBar('Could not upload the file. Please try again.');
      } else {
        this.isUploaded = true;
        this.getPendingIndexingClients();
        this.openSnackBar('File uploaded successfully.');
      }

    }, err => {
      this.isUploading = false;
      this.openSnackBar('Could not upload the file: ' + this.uploadParam.file);
    });
  }

  initiateIndexing() {
    switch (this.indexParam.clientName) {
      case "Expere Base":
        this.indexParam.clientName = "expere";
        break;
      case "Sun Trust":
        this.indexParam.clientName = "suntrust";
        break;
      case "JPMC Private Banking":
        this.indexParam.clientName = "jpmc";
        break;
      case "M&T":
        this.indexParam.clientName = "mnt";
        break;
      case "BMO Harris":
        this.indexParam.clientName = "bmoharris";
        break;
      case "Synovus Mortgage":
        this.indexParam.clientName = "synovus";
        break;
      default:
        break;
    }
    // console.log(this.indexParam);
    // this.isIndexing = true;
    this.isUploading = true;

    this.resultService.index(this.indexParam).subscribe(response => {
      console.log(response);
      setTimeout(() => {
        this.isUploading = false;
        if (response.status != "Error") {
          // this.isUploaded = false;
          this.getPendingIndexingClients();
          this.openSnackBar('Indexing initiated.');
        } else {
          this.openSnackBar(response.Description);
        }
      }, 1000);

    }, err => {
      this.isUploading = false;
      this.openSnackBar('Could not initiate indexing: ' + err);
    });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, "", {
      duration: 2000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

}
