import { Component, OnInit, OnDestroy } from '@angular/core';
import { DashboardService } from './dashboard.service';
import { Search } from './model/search.model';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { ResultService } from '../result/result.service';
import { SimpleSnackBar, MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  myForm: FormGroup;
  searchTypes: Search[] = [];
  documentRules: Search[] = [];
  docProperties: Search[] = [];
  docContents: Search[] = [];
  documentPackages: Search[] = [];
  documentNotes: Search[] = [];
  staticContents: Search[] = [];
  searchDocumentTests: Search[] = [];
  isLoading: boolean;
  allSearchField = [];
  folderLocation: string;
  date: string;
  documentRulesToggle = false;
  docPropertiesToggle = false;
  docContentsToggle = false;
  documentPackagesToggle = false;
  documentNotesToggle = false;
  staticContentsToggle = false;
  searchDocumentTestToggle =  false;
  selectAllToggle = false;
  private folderLocationSub: Subscription;
  constructor(private dashboardService: DashboardService, private resultService: ResultService, private fb: FormBuilder, private snackbar: MatSnackBar) { }
  ngOnInit() {
    this.folderLocationSub = this.resultService.getFolderpath().subscribe(res => {
      this.folderLocation = res.path;
      this.date = res.date;
    });
    this.myForm = this.fb.group({
      fieldToSearch: this.fb.array([]),
      searchItem: new FormControl('', [
        Validators.required,
      ]),
      searchType: new FormControl("Exact Search Phrase", [
        Validators.required,

      ]),
    });
    this.searchTypes = this.dashboardService.getSearchType();
    this.documentRules = this.dashboardService.getDocumentRules();
    this.docProperties = this.dashboardService.getDocProperties();
    this.docContents = this.dashboardService.getDocContents();
    this.documentPackages = this.dashboardService.getDocumentPackages();
    this.documentNotes = this.dashboardService.getDocumentNotes();
    this.staticContents = this.dashboardService.getStaticContents();
    this.searchDocumentTests = this.dashboardService.getSearchDocumentTests()

  }
  onChange(field: string, isChecked: boolean) {
    const fieldsArray = <FormArray>this.myForm.controls.fieldToSearch;
    if (isChecked) {
      fieldsArray.push(new FormControl(field));
    } else {
      let index = fieldsArray.controls.findIndex(x => x.value == field)
      fieldsArray.removeAt(index);
    }

  }

  submit() {
    if (this.myForm.value.searchItem == null || this.myForm.value.searchItem == "") {
      this.snackbar.open("Please Select Valid Search Term", null, { duration: 3000, verticalPosition: 'top' });
      return;
    }
    if (this.myForm.value.fieldToSearch.length > 0) {
      this.isLoading = true;
      if (this.selectAllToggle) {
        this.resultService.getAllSecected(this.myForm.value.searchType, this.myForm.value.searchItem);
        return;
      }
      //console.log("checkbox data:--   "+this.myForm.value.fieldToSearch) 

      this.resultService.getSearchData(this.myForm.value.searchType, this.myForm.value.searchItem, this.myForm.value.fieldToSearch);
    }
    else {
      //  console.log("......................");
      this.resultService.getAllSecected(this.myForm.value.searchType, this.myForm.value.searchItem);
      this.snackbar.open("All Fields Are Selected", null, { duration: 3000, verticalPosition: 'top' });
      return;
    }
  }
  onAllToggle(enable: boolean) {
    if (enable) {
      this.myForm.value.fieldToSearch.length = 1;
      this.selectAllToggle = true;
      this.documentRulesToggle = true;
      this.docPropertiesToggle = true;
      this.docContentsToggle = true;
      this.documentPackagesToggle = true;
      this.documentNotesToggle = true;
      this.staticContentsToggle = true;
      this.searchDocumentTestToggle = true;
      
    }
    else {
      this.myForm.value.fieldToSearch.length = 0;
      this.selectAllToggle = false;
      this.documentRulesToggle = false;
      this.docPropertiesToggle = false;
      this.docContentsToggle = false;
      this.documentPackagesToggle = false;
      this.documentNotesToggle = false;
      this.staticContentsToggle = false;
      this.searchDocumentTestToggle = false;
    }
  }
  ondocumentRulesToggle(enable: boolean) {
    const names = this.documentRules.map(function (item) {
      return item['name'];
    });
    //console.log(names)
    let documentRulesArray = <FormArray>this.myForm.controls.fieldToSearch;
    var d = names.toString();
    // console.log(d)
    if (enable) {
      this.documentRulesToggle = true;
      documentRulesArray.push(new FormControl(d))
    } else {
      this.documentRulesToggle = false;
      let index = documentRulesArray.controls.findIndex(x => x.value == d)
      documentRulesArray.removeAt(index);
    }
  }
  ondocPropertiesToggle(enable: boolean) {
    const names = this.docProperties.map(function (item) {
      return item['name'];
    });
    let docPropertiesArray = <FormArray>this.myForm.controls.fieldToSearch;
    var d = names.toString();
    if (enable) {
      this.docPropertiesToggle = true;
      docPropertiesArray.push(new FormControl(d))
    } else {
      this.docPropertiesToggle = false;
      let index = docPropertiesArray.controls.findIndex(x => x.value == d)
      docPropertiesArray.removeAt(index);
    }
  }
  ondocContentsToggle(enable: boolean) {
    const names = this.docContents.map(function (item) {
      return item['name'];
    });
    let docContentsArray = <FormArray>this.myForm.controls.fieldToSearch;
    var d = names.toString();
    if (enable) {
      this.docContentsToggle = true;
      docContentsArray.push(new FormControl(d))
    } else {
      this.docContentsToggle = false;
      let index = docContentsArray.controls.findIndex(x => x.value == d)
      docContentsArray.removeAt(index);
    }
  }
  ondocumentPackagesToggle(enable: boolean) {
    const names = this.documentPackages.map(function (item) {
      return item['name'];
    });
    let documentPackagessArray = <FormArray>this.myForm.controls.fieldToSearch;
    var d = names.toString();
    if (enable) {
      this.documentPackagesToggle = true;
      documentPackagessArray.push(new FormControl(d))
    } else {
      this.documentPackagesToggle = false;
      let index = documentPackagessArray.controls.findIndex(x => x.value == d)
      documentPackagessArray.removeAt(index);
    }
  }
  ondocumentNotesToggle(enable: boolean) {
    const names = this.documentNotes.map(function (item) {
      return item['name'];
    });
    let documentNotesArray = <FormArray>this.myForm.controls.fieldToSearch;
    var d = names.toString();
    if (enable) {
      this.documentNotesToggle = true;
      documentNotesArray.push(new FormControl(d))
    } else {
      this.documentNotesToggle = false;
      let index = documentNotesArray.controls.findIndex(x => x.value == d)
      documentNotesArray.removeAt(index);
    }
  }
  onstaticContentsToggle(enable: boolean) {
    const names = this.staticContents.map(function (item) {
      return item['name'];
    });
    let staticContentsArray = <FormArray>this.myForm.controls.fieldToSearch;
    var d = names.toString();
    if (enable) {
      this.staticContentsToggle = true;
      staticContentsArray.push(new FormControl(d))
    } else {
      this.staticContentsToggle = false;
      let index = staticContentsArray.controls.findIndex(x => x.value == d)
      staticContentsArray.removeAt(index);
    }
  }

  onSearchDocumentTestToggle(enable: boolean){
    const names = this.searchDocumentTests.map(function (item) {
      return item['name'];
    });
    let staticContentsArray = <FormArray>this.myForm.controls.fieldToSearch;
    var d = names.toString();
    if (enable) {
      this.searchDocumentTestToggle = true;
      staticContentsArray.push(new FormControl(d))
    } else {
      this.searchDocumentTestToggle = false;
      let index = staticContentsArray.controls.findIndex(x => x.value == d)
      staticContentsArray.removeAt(index);
    }



  }
  ngOnDestroy() {
    this.folderLocationSub.unsubscribe();
  }
}
